import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Main = ({ className, dataSource, columns }) => {


	return (
		<div className={className}>
			{
				dataSource && dataSource.map((row,r) => {
					return (<div className="row" key={r}>
						{
							columns.map((t, i) => {
								return (<div className="td" key={"co" + i}>
									<span className="l">{t.title}</span>
									<span className="r">{t.render ? t.render(row[t.dataIndex], row) : row[t.dataIndex]}</span>
								</div>)
							})
						}
					</div>)
				})
			}
		</div>
	);
};

export default styled(Main)`
	display: flex;
    flex-direction: column;
    justify-content: center;
    width:99%;
    margin: 0 auto;
	gap:10px;
	.row{
		background-color: #232323;
		border-radius: 8px;
		padding:10px;
		display: flex;
		flex-direction: column;
		gap:10px;
		.td{
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			.l{
				color:#5D5D5D;
			}
			.r{
				color:#fff;
				max-width:60%;
				overflow: hidden;
			}
		}
	}
`
