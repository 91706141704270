import React, { useState, useEffect } from "react";
import { ConnectButton } from '@rainbow-me/rainbowkit';
import styled from "styled-components";
import { useSendTransaction, useAccount, useSignMessage, useConfig } from 'wagmi'
import { parseEther } from 'viem';
import { Space, Button, InputNumber, Modal, message, Spin, Empty } from "antd";
import { LockOutlined, CheckCircleOutlined } from '@ant-design/icons';


import webconfig from '../webconfig';
import forHome from "../controls/for-home";
import store from '../utils/store';
import * as formatter from '../utils/formatter';
import common from '../utils/common';
import got from '../utils/request';
import valitor from '../utils/valitor';
import { alertInfo, alertOk, alertError, notiError, msgError, msgOK } from "../utils/antd-helper";
import Mint from './Mint';

const Main = ({ className, projectid, accountAddr }) => {
    const account = useAccount();
    const [products, setProducts] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [mintingPackage, setMintingPackage] = useState();

    useEffect(() => {
        loadList();
        return () => {
            console.log('unmount');
        }
    }, [projectid]);

    const loadList = () => {
        forHome.getData(projectid, setLoading, setProducts);
    }

    const onBuy = (productObj, packageObj) => {
        setIsModalOpen(true);
        setMintingPackage(packageObj);
    }

    return (
        <div className={className}>
            <Spin spinning={loading}>
                <div className="con">
                    {!products || products.length == 0 ? <div className="center"><Empty /></div> :
                        products.map((p, i) => (<div className="product-box" key={i}>
                            <div className="box-header">
                                <div className="c">
                                    <label>{p.productName}</label>
                                    <b>{p.title2}</b>
                                </div>
                                <div className="d"></div>
                                <div className="c">
                                    <label>Total minted</label>
                                    <b>{p.totalMinted}</b>
                                </div>
                                <div className="d"></div>
                                <div className="c">
                                    <label>Estimated total reward</label>
                                    <b>{p.totalReward}</b>
                                </div>
                                <div className="d"></div>
                                <div className="c">
                                    <label>Estimated 24 Hours rewards day</label>
                                    <b>{p.reward24H}</b>
                                </div>
                            </div>
                            <div className="box-con">
                                {!p.packages || p.packages.length == 0 ? <div className="center"><Empty /></div> : p.packages.map((t, j) => (<div key={i + "-" + j} className="package-box">
                                    <div className="img"><img src={t.pic} /></div>
                                    <div className="right">
                                        <div className="t1">{t.packageName}</div>
                                        <div className="t2">
                                            <label>Price</label>
                                            <span>{t.price} <font>USDT</font></span>
                                        </div>
                                        <div className="t2">
                                            <label>Multiplier</label>
                                            <span>{t.multiplier} <font>x</font></span>
                                        </div>
                                        <div className="t2">
                                            <label>The term</label>
                                            <span>{t.theTerm} <font>Days</font></span>
                                        </div>
                                        <div className="t2">
                                            <label>Description</label>
                                            <span>{t.description}</span>
                                        </div>
                                        {!account || !account.address ? <div className="btn-box-login"><ConnectButton showBalance={false} accountStatus='avatar' chainStatus="none" /></div> :
                                            t.isLive ? <div className="btn" onClick={() => onBuy(p, t)}>Mint</div> : <div className="btn btn-disabled">Mint</div>}
                                    </div>
                                </div>))}
                            </div>
                        </div>))}
                </div>
            </Spin>
            <Modal mask={true} destroyOnClose={true} width={window.innerWidth > 450 ? 450 : window.innerWidth} title="" open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer={null}>
                <Mint data={mintingPackage} onCancel={() => { setIsModalOpen(false); loadList() }} />
            </Modal>
        </div>
    );
};

export default styled(Main)`
    display: block;
    .con{
        display: flex;
        flex-direction: column;
        max-width: 1168px;
        margin:0 auto;
        gap:32px;
        .product-box{
            display: flex;
            flex-direction: column;
            border-radius: 8px;
            overflow: hidden;
            border: 1px solid #222;
            background: #141414;
            .box-header{
                width: 90%;
                background: #141414;
                min-height:48px;
                padding:16px 24px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap:24px;
                .d{
                    border-left: 2px solid #2d2d2d;
                    width: 0px;
                    height: 37px;
                }
                .c{
                    display: flex;
                    flex-direction: column;
                    label{
                        color: #A1A1A1;
                        font-size: 14px;
                        font-weight: 300;
                    }
                    b{
                        color: #FFF;
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
            }
            .box-con{
                background: #0C0C0C;
                padding:16px;
                gap:16px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                .package-box{
                    width:calc(50% - 40px);
                    padding:16px;
                    border-radius: 8px;
                    display: flex;
                    flex-direction: row;
                    background: #141414;
                    justify-content: space-between;
                    .img{
                        width:40%;
                        border-radius: 8px;
                        img{
                            width: 100%;
                        }
                    }
                    .right{
                        display: flex;
                        flex-direction: column;
                        width:55%;
                        gap:8px;
                        .t1{
                            font-size: 14px;
                            height:22px;
                            line-height: 22px;
                        }
                        .t2{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            min-height: 22px;
                            gap: 20px;
                            label{
                                color:#5D5D5D;
                            }
                            span{
                                color:#fff;
                                font{
                                    color:#22E531;
                                }
                            }
                        } 
                        .btn-box-login{
                            div{
                                width: 100%!important;
                            }
                            button{
                                width: 100%!important;
                                height:34px!important;
                                margin:8px auto 0;
                                justify-content: center;
                            }
                        }
                        .btn{
                            width:100%;
                            height:34px;
                            margin:8px auto 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            overflow: hidden;
                            border-radius: 8px;
                            background: #22E531;
                            color:#000;
                            cursor: pointer;
                            transition: all .3s;
                            text-align: center;
                            &:hover{
                                margin:10px auto 0;
                                width:98%;
                                height:30px;
                            }
                        }
                        .btn-disabled{
                            background: #515151;
                            color: #2f2f2f;
                            cursor: not-allowed;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 800px) {
        .con{
            .product-box{
                .box-header{
                    flex-wrap: wrap;
                    width: 100%;
                    padding: 10px 10px;
                    .d{
                        display: none;
                    }
                    .c:first-child{
                        width:100%;
                        border-bottom: 1px solid #222;
                        padding-bottom: 10px;
                    }
                }
                .box-con{
                    .package-box{
                        width:calc(100% - 32px);
                        .right{
                            width:58%;
                            .t1{
                                height:20px;
                                line-height: 20px;
                            }
                            .t2{
                                height:20px;
                                line-height: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
`
