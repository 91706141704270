import React, { useState, useEffect } from "react";
import { ConnectButton } from '@rainbow-me/rainbowkit';
import styled from "styled-components";
import { useSendTransaction, useAccount, useSignMessage, useContractWrite, useBlockNumber, useBalance, useWalletClient, useQueryClient } from 'wagmi';
import { Space, Button, Input, Modal, message, Spin } from "antd";

import webconfig from '../webconfig';
import forHome from "../controls/for-home";
import forMint from "../controls/for-mint";
import store from '../utils/store';
import * as formatter from '../utils/formatter';
import common from '../utils/common';
import got from '../utils/request';
import valitor from '../utils/valitor';
import tokenABI from '../controls/token-abi';

import { alertInfo, alertOk, alertError, notiError, msgError } from "../utils/antd-helper";

let blockHeight = 0;

const Main = ({ className, onCancel, data }) => {
    const [packageObj, setPackageObj] = useState(data);
    const [loading, setLoading] = useState(false);
    const [buyCount, setBuyCount] = useState(1);
    const [amount, setAmount] = useState(data?.price);
    const blockNumber = useBlockNumber({
        chainId: webconfig.chainId,
        watch: true
    });
    const { writeAsync, status } = useContractWrite({
        address: "",
        abi: tokenABI.abi,
        chainId: webconfig.chainId
    });

    useEffect(() => {
        setPackageObj(data);
        setInterval(() => {
            console.log("blockNumber2", blockNumber.status, blockNumber.data);
            if (blockNumber.data) {
                blockHeight = blockNumber.data.toString();
            }
        }, 1000);
        setTimeout(() => {
            blockNumber.refetch();
        }, 3000);
    }, [data]);
    const getStatus = () => {
        return document.getElementById("status").innerText;
    }
    const getBlockNumber = () => {
        return blockHeight || document.getElementById("blockNumber").innerText;
    }

    const onConfrm = async () => {
        forMint.mint(writeAsync, setLoading, packageObj.address, amount, buyCount, getStatus, onCancel);
    }
    const onAddCount = (v) => {
        setBuyCount(t => {
            v = t + v;
            if (v < 2) {
                v = 1;
            } else if (v > 50) {
                msgError("Max 50");
                v = 50;
            }
            if (v > packageObj.total) {
                msgError("Max " + packageObj.total);
                v = packageObj.total;
            }
            setAmount(v * packageObj.price);
            return v;
        });
    }



    return (
        <div className={className}>
            <div className="none" id="status">{status}</div>
            <div className="none" id="blockNumber">{blockNumber.data}</div>

            <div className="package-box">
                <div className="img"><img src={packageObj.pic} /></div>
                <div className="right">
                    <div className="t1">{packageObj.packageName}</div>
                    <div className="t2">
                        <label>Multiplier</label>
                        <span>{packageObj.multiplier} <font>x</font></span>
                    </div>
                    <div className="t2">
                        <label>The term</label>
                        <span>{packageObj.theTerm} <font>Days</font></span>
                    </div>
                    <div className="t2">
                        <label>Description</label>
                        <span>{packageObj.description}</span>
                    </div>
                </div>
            </div>

            <div className="status-box">
                <div className="left">
                    <label><i className="animate-ping"></i></label>
                    <span>Live</span>
                </div>
                <div className="right">
                    <div className="txt">
                        <label>Total Minted</label>
                        <span>{packageObj.mintedPer}<font>({packageObj.minted}/{packageObj.total})</font></span>
                    </div>
                    <div className="progress">
                        <label style={{ width: packageObj.mintedPer }}></label>
                    </div>
                </div>
            </div>

            <div className="amount-box">
                <div className="left">
                    <label>Amount：</label>
                    <font>{amount}</font>&nbsp;
                    USDT
                </div>
                <div className="right">
                <img width="24px" src={process.env.PUBLIC_URL + "/imgs/-.png"} onClick={() => onAddCount(-1)} />
                    <label>{buyCount}</label>                    
                    <img width="24px" src={process.env.PUBLIC_URL + "/imgs/+.png"} onClick={() => onAddCount(1)} />
                </div>
            </div>

            <Button type="primary" loading={loading} disabled={loading || !blockNumber.data} className="btn-middle" onClick={onConfrm}>
                {loading ? status : "Mint"}
            </Button>
        </div>
    );
};

export default styled(Main)`
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:100%;
    margin: 0 auto;
    gap:24px;
    .none{
        width:0px;
        height:0px;
        display: block;
        overflow: hidden;
    }
    .package-box{
        width:100%;
        padding-top:26px;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .img{
            width:40%;
            border-radius: 8px;
            img{
                width: 100%;
            }
        }
        .right{
            display: flex;
            flex-direction: column;
            width:55%;
            gap:10px;
            .t1{
                font-size: 14px;
                height:22px;
                line-height: 22px;
            }
            .t2{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                height:22px;
                line-height: 22px;
                label{
                    color:#5D5D5D;
                }
                span{
                    color:#fff;
                    font{
                        color:#22E531;
                    }
                }
            } 
            .btn{
                width:100%;
                height:34px;
                margin:8px auto 0;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                border-radius: 8px;
                background: #22E531;
                color:#000;
                cursor: pointer;
                transition: all .3s;
                text-align: center;
                &:hover{
                    margin:10px auto 0;
                    width:98%;
                    height:30px;
                }
            }
        }
    }
    .status-box{
        width: 100%;
        height:73px;        
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: #242628;
        border-radius: 8px;
        .left{
            width:20%;
            height:100%;
            display: flex;
            gap:10px;
            align-items: center;
            justify-content: center;
            border-right:1px solid #3C3C3C;
            label{
                position: relative;
                top:0;  
                &::after{
                    content:"";
                    width:6px;
                    height:6px;
                    border-radius: 10px;
                    display: block;
                    overflow: hidden;
                    position: absolute;
                    top:2px;
                    left:2px;
                    background-color: #22E531;
                    z-index: 1;
                }
                .animate-ping{
                    width:10px;
                    height:10px;
                    border-radius: 10px;
                    display: block;
                    overflow: hidden;                              
                }
            }            
        }
        .right{
            width:70%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding:0 5%;
            gap:8px;
            font-size:14px;
            .txt{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
            .progress{
                width: 100%;
                height:13px;
                display: block;
                overflow: hidden;
                border-radius: 4px;
                background-color: #141414;
                label{
                    height:13px;
                    display: block;
                    overflow: hidden;
                    border-radius: 4px;
                    background-color: #22E531;
                }
            }
        }
    }
    .amount-box{
        width: 100%;
        height:40px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .left{
            color:#22E531;
            label{
                color:#5D5D5D;
            }
            font{
                color:#fff;
            }
        }
        .right{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap:10px;
            img{
                cursor: pointer;
            }
        }
    }
    
`
