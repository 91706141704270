import axios from 'axios';
let baseUrl = window.apiURL || "https://uat-xess.cess.network/api";
let proxyUrl = null;

async function ajax(url, data, headers = {}) {
	try {
		if (url.indexOf("http") == -1) {
			url = baseUrl + url;
		}
		// let token = store.get("token");
		// if (token) {
		// 	headers["token"] = token;
		// }
		if (proxyUrl) {
			headers['target'] = url;
			url = proxyUrl;
		}
		let op = {
			method: 'get',
			url,
			headers
		};
		if (data) {
			op.data = data;
			op.method = "post";
		}
		let res = await axios(op);
		let ret = res.data;
		if (typeof ret == 'string') {
			try {
				ret = JSON.parse(ret);
			} catch (e2) { }
		}
		if (ret.code == 200) {
			ret.msg = "ok";
		}
		return ret;
	} catch (e) {
		console.log(e);
		if (e.response?.data?.msg) {
			return { msg: e.response.data.msg };
		} else if (e.response?.data?.data) {
			return { msg: e.response.data.data };
		}
		return { msg: e.message };
	}
}
export default ajax;
