import webconfig from '../webconfig';
import api from "./api";
import store from '../utils/store';
import broadcast from "../utils/broadcast";
import * as formatter from '../utils/formatter';
import common from '../utils/common';
import got from '../utils/request';
import valitor from '../utils/valitor';
import { noti, alertInfo, alertOk, alertError, msgOK, msgError } from "../utils/antd-helper";
import tokenABI from '../controls/token-abi';
import nftABI from '../controls/nft-abi';


function getAddr() {
    return store.get('addr');
}
const sendMeToken = async (writeAsync, setLoading, tokenAmount) => {
    let ret = { msg: "" };
    try {
        setLoading(true);
        let receiveAddress = getAddr();
        // let tokenAmount = 100;
        ret = await writeAsync({
            address: webconfig.contractAddressToken,
            abi: tokenABI.abi,
            chainId: webconfig.chainId,
            functionName: '_mintToken',
            args: [receiveAddress, 1e6 * tokenAmount]
        });
        console.log(ret);
        if (ret.hash) {
            ret.msg = "ok";
            ret.data = ret.hash;
        }
    } catch (e) {
        ret.msg = e.message || e;
    } finally {
        setLoading(false);
        if (ret.msg != 'ok') {
            alertError(ret.msg);
        }
        return ret;
    }
}
const mint = async (writeAsync, setLoading, nftHash, amount, quantity, getStatus, closeModel) => {
    let ret = { msg: "" };
    try {
        setLoading(true);
        console.log('contractAddress', nftHash);
        ret = await writeAsync({
            address: webconfig.contractAddressToken,
            abi: tokenABI.abi,
            chainId: webconfig.chainId,
            functionName: 'approve',
            args: [nftHash, (amount * 1e6).toString()]
        }, {
            onSuccess: (e) => {
                console.log('onSuccess', e);
            }, onSettled: (e) => {
                console.log('onSettled', e);
            }, onError: (e) => {
                console.log('onError', e);
            }, onCancel: (e) => {
                console.log('oncancel', e);
            }
        });
        while (getStatus() != "success") {
            await common.sleep(600);
        }
        // console.log(ret);
        // await ret.wait();    
        await common.sleep(2000);
        if (ret.hash) {
            ret = await writeAsync({
                address: nftHash,
                abi: nftABI.abi,
                chainId: webconfig.chainId,
                functionName: 'batchMint',
                args: [quantity.toString()]
            });
            if (ret.hash) {
                ret.msg = "ok";
                ret.data = ret.hash;
                ret = await api.mintNFT({
                    "txhash": ret.hash,
                    "from": getAddr(),
                    "to": nftHash,
                    "value": amount.toString(),
                    "quantity": quantity
                });
                if (ret.msg == 'ok') {
                    alertOk('Mint successfuly');
                    closeModel();
                }
            }
        } else {
            ret.msg = "approve error";
        }
    } catch (e) {
        ret.msg = e.message || e;
        if (ret.msg.includes("User rejected")) {
            // msgError("User rejected");
            ret.msg = 'ok';
        }
    } finally {
        setLoading(false);
        if (ret.msg != 'ok') {
            alertError(ret.msg);
        }
        return ret;
    }
}

export default {
    getAddr,
    sendMeToken,
    mint
}