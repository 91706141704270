import webconfig from '../webconfig';
import api from "./api";
import store from '../utils/store';
import * as formatter from '../utils/formatter';
import common from '../utils/common';
import got from '../utils/request';
import valitor from '../utils/valitor';
import { noti, alertInfo, alertOk, alertError, msgOK, msgError } from "../utils/antd-helper";
import forHome from "./for-home";

export default {
    getHolderData, getPurchaseRecords
}
async function getHolderData(setLoading, setData) {
    if (!setLoading) setLoading = () => { };
    setLoading(true);
    let ret = await api.getMyHolders(1, setLoading);
    setLoading(false);
    if (ret?.msg == 'ok' && ret?.data && ret.data.length > 0) {
        ret.data.forEach(t => {
            if (t.nftImg) {
                t.nftImg = formatter.formatNFTImageUrl(t.nftImg);
            }
            if (t.term) {
                t.term = t.term.replace("days", "");
            }
        });
    }
    setData(ret.data);
    return ret;
}
async function getPurchaseRecords(setLoading, setData, setRecordColumns) {
    if (!setLoading) setLoading = () => { };
    setLoading(true);
    let ret = await api.getBuyRecords();
    // const dataSource = [
    //     {
    //         key: '1',
    //         events: 'Purchased',
    //         hash: "0x21354sad54a565sd56a4s5d6e5e4",
    //         quantity: 5,
    //         amount: 'Quantity',
    //         date: '2023/12/24 15:30:42',
    //         status: 'Confirmed'
    //     },
    //     {
    //         key: '2',
    //         events: 'Purchased',
    //         hash: "0x21354sad54a565sd56a4s5d6e5e4",
    //         quantity: 5,
    //         amount: 'Quantity',
    //         date: '2023/12/24 15:30:42',
    //         status: 'Unconfirmed'
    //     },
    //     {
    //         key: '3',
    //         events: 'Purchased',
    //         hash: "0x21354sad54a565sd56a4s5d6e5e4",
    //         quantity: 5,
    //         amount: 'Quantity',
    //         date: '2023/12/24 15:30:42',
    //         status: 'Unconfirmed'
    //     },
    //     {
    //         key: '4',
    //         events: 'Purchased',
    //         hash: "0x21354sad54a565sd56a4s5d6e5e4",
    //         quantity: 5,
    //         amount: 'Quantity',
    //         date: '2023/12/24 15:30:42',
    //         status: 'Confirmed'
    //     },
    //     {
    //         key: '5',
    //         events: 'Purchased',
    //         hash: "0x21354sad54a565sd56a4s5d6e5e4",
    //         quantity: 5,
    //         amount: 'Quantity',
    //         date: '2023/12/24 15:30:42',
    //         status: 'Confirmed'
    //     },
    // ];
    // let ret = { msg: 'ok', data: dataSource };

    const columns = [
        {
            title: 'Events',
            dataIndex: 'event',
            key: 'event',
        },
        {
            title: 'Transaction Hash',
            dataIndex: 'hash',
            key: 'hash',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Date(UTC+0)',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (txt, row) => {
                if (txt == "Confirmed"||txt=="confirmed") {
                    return <div style={{ color: "#22E531" }}>{txt}</div>;
                } else {
                    return txt;
                }
            }
        },
    ];
    setRecordColumns(columns);
    setLoading(false);
    if (ret.msg != 'ok' || !ret.data || ret.data.length == 0) {
        return ret;
    }
    ret.data.forEach(t => {
        t.hash = formatter.formatAddress(t.txhash);
        t.amount = t.amount + " USDT";
        t.key = t.id;
    });
    setData(ret.data);

    return ret;
}