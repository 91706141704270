import React, { useState, useEffect } from "react";
import { ConnectButton } from '@rainbow-me/rainbowkit';
import styled from "styled-components";
import { useSendTransaction, useAccount, useSignMessage, useConfig } from 'wagmi'
import { parseEther } from 'viem';
import { Space, Tabs,Empty , Modal, Table, Spin } from "antd";
import { LockOutlined, CheckCircleOutlined } from '@ant-design/icons';

import webconfig from '../webconfig';
import forDashborad from "../controls/for-dashborad";
import store from '../utils/store';
import * as formatter from '../utils/formatter';
import common from '../utils/common';
import got from '../utils/request';
import valitor from '../utils/valitor';
import { alertInfo, alertOk, alertError, notiError, msgError, msgOK } from "../utils/antd-helper";
import MList from './MList';

let ignore = false;

const Main = ({ className, projectid, accountAddr }) => {
    const account = useAccount();
    const [holders, setHolders] = useState([]);
    const [records, setRecords] = useState([]);
    const [recordColumns, setRecordColumns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const init = async () => {
        resizeWindow();
        window.addEventListener('resize', resizeWindow);
        await forDashborad.getHolderData(setLoading, setHolders);
        await forDashborad.getPurchaseRecords(setLoading, setRecords, setRecordColumns);
    }
    const resizeWindow = () => {
        setIsMobile(window.innerWidth < 800);
    }

    const items = [
        {
            key: '1',
            label: 'Holders',
            children: <div className="holders">
                {!holders || holders.length == 0 ? <div className="center"><Empty /></div> :
                    holders.map((t, j) => (<div key={"-" + j} className="package-box">
                        <div className="img"><img src={t.nftImg} /></div>
                        <div className="right">
                            <div className="t2">
                                <label>Hold</label>
                                <span>{t.hold} <font>x</font></span>
                            </div>
                            <div className="t2">
                                <label>The term</label>
                                <span>{t.term} <font>Days</font></span>
                            </div>
                        </div>
                    </div>))}
            </div>,
        },
        {
            key: '2',
            label: 'Purchase records',
            children: <div className="records">
                {isMobile ? <MList dataSource={records} columns={recordColumns} /> : <Table dataSource={records} columns={recordColumns} />}
            </div>,
        }
    ];


    useEffect(() => {
        ignore = false;
        init();
        return () => {
            ignore = true;
        }
    }, []);

    if (!account.address) {
        return <div className="btn-box-login"><ConnectButton showBalance={false} accountStatus='avatar' chainStatus="none" /></div>;
    }


    return (
        <div className={className}>
            <Spin spinning={loading}>
                <div className="con">
                    <Tabs defaultActiveKey="1" items={items} />
                </div>
            </Spin>
        </div>
    );
};

export default styled(Main)`
    display: block;
    .holders{
        padding:16px;
        gap:16px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .package-box{
            width:220px;
            padding:16px;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            background: #141414;
            justify-content: space-between;
            .img{
                width:100%;
                border-radius: 8px;
                img{
                    width: 100%;
                }
            }
            .right{
                display: flex;
                flex-direction: column;
                width:100%;
                gap:10px;
                .t1{
                    font-size: 14px;
                    height:22px;
                    line-height: 22px;
                }
                .t2{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    height:22px;
                    line-height: 22px;
                    label{
                        color:#5D5D5D;
                    }
                    span{
                        color:#fff;
                        font{
                            color:#22E531;
                        }
                    }
                } 
                .btn{
                    width:100%;
                    height:34px;
                    margin:8px auto 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
                    border-radius: 8px;
                    background: #22E531;
                    color:#000;
                    cursor: pointer;
                    transition: all .3s;
                    text-align: center;
                    &:hover{
                        margin:10px auto 0;
                        width:98%;
                        height:30px;
                    }
                }
            }
        }
    }
    @media (max-width: 800px) {
        .holders{
            padding: 8px;
            gap:8px;
            .package-box{
                padding:8px;
                width: calc(50% - 20px);
            }
        }
    }
`
