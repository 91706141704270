import React, { useState, useEffect } from "react";
import { ConnectButton } from '@rainbow-me/rainbowkit';
import styled from "styled-components";
import { useSendTransaction, useAccount, useSignMessage, useContractWrite } from 'wagmi'
import { parseEther } from 'viem';
import { Space, Button, Input, Modal, message, Spin } from "antd";

import webconfig from '../webconfig';
import forHome from "../controls/for-home";
import forMint from "../controls/for-mint";
import store from '../utils/store';
import * as formatter from '../utils/formatter';
import common from '../utils/common';
import got from '../utils/request';
import valitor from '../utils/valitor';

import { alertInfo, alertOk, alertError, notiError, msgError } from "../utils/antd-helper";

const Main = ({ className, onCancel, data }) => {
    const account = useAccount();
    const [loading, setLoading] = useState(false);
    const { writeAsync } = useContractWrite({
        address: webconfig.contractAddress,
        chainId: webconfig.chainId
    });


    const onMintToken = async () => {
        forMint.sendMeToken(writeAsync, setLoading, 100000);
    }

    return (
        <div className={className}>
            <ConnectButton showBalance={true} accountStatus='avatar' />
            {
                account && account.address && <>
                    <Button type="primary" loading={loading} disabled={loading} className="btn-middle" onClick={onMintToken}>Get 100000 Arbitrum-USDT</Button>
                </>
            }
            <div>
                Arbitrum Eth Faucet
            </div>
            <a href="https://www.alchemy.com/faucets/arbitrum-sepolia" target="_blank">https://www.alchemy.com/faucets/arbitrum-sepolia</a>
            <div>
                RPC Config:<br />
                RPC URL：https://sepolia-rollup.arbitrum.io/rpc<br />
                ID：421614<br />
                Name：Arbitrum Sepolia<br />
                Token：ETH<br />
            </div>
        </div>
    );
};

export default styled(Main)`
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:100px 30px;
    gap:24px;
    
`
