import copy from "copy-to-clipboard";
import { msgOK } from "./antd-helper";


function getQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
}
const onCopy = (text) => {
    copy(text);
    msgOK("Copied");
}
const onCopyInvitationLink = (code) => {
    onCopy('https://cess.cloud/daocm?code=' + code);
}
function isMobile() {
    let isMobile = false;
    if (
        navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
    ) {
        // console.log('移动端');
        isMobile = true;
    }
    if (document.body.clientWidth < 800) {
        isMobile = true;
    }
    return isMobile;
}

function isJson(str) {
    let isValid = true;
    if (typeof str == "string") {
        try {
            let obj = JSON.parse(str);
            if (typeof obj == "object" && obj) {
            } else {
                isValid = false;
            }
        } catch (e) {
            console.log("error：" + str + "!!!" + e);
            isValid = false;
        }
    }
    return isValid;
}
function clone(obj) {
    return JSON.parse(JSON.stringify(obj));
}
function sleep(minisec) {
    return new Promise((resolve, reject) => {
        setTimeout(function () {
            resolve();
        }, minisec);
    });
}
export default {
    getQueryString,
    onCopy,
    onCopyInvitationLink,
    isMobile,
    isJson,
    clone,
    sleep
}