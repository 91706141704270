import store from '../utils/store';
import got from '../utils/request';
import { noti, alertInfo, alertOk, alertError, msgOK, msgError } from "../utils/antd-helper";

export default {
    getProjects,
    getProducts,
    getNFTs,
    getNFTDetail,
    getBuyRecords,
    getMyHolders,
    getAdress,
    setAdress,
    mintNFT
};
async function getProjects() {
    let ret = await got("/xess/customer/projects");
    if (ret.msg == 'ok' && ret.data && ret.data.length > 0) {
        ret.data.sort((t1, t2) => t1.sort - t2.sort).forEach(t => t.label = t.projectName);
        if (ret.data.length > 5) {
            ret.data = ret.data.slice(0, 5);
        }
    }
    return alertErr(ret);
}
async function getProducts(projId) {
    let ret = await got("/xess/customer/products?projectId=" + projId);
    return alertErr(ret);
}
async function getNFTs(productId) {
    let url = "/xess/customer/nfts";
    if (productId) {
        url += "?productId=" + productId;
    }
    let ret = await got(url,[]);
    return alertErr(ret);
}
async function getNFTDetail(nftId) {
    let ret = await got("/xess/customer/nft?nftId=" + nftId);
    return alertErr(ret);
}
async function getBuyRecords() {
    let ret = await got("/xess/customer/records?address=" + getAdress());
    return alertErr(ret);
}
async function getMyHolders() {
    let ret = await got("/xess/customer/holders?address=" + getAdress());
    return alertErr(ret);
}
async function mintNFT(params) {
    let ret = await got("/xess/customer/mint", params);
    return alertErr(ret);
}
function getAdress() {
    return store.get('addr');
}
function setAdress(addr) {
    store.set('addr', addr);
}
function alertErr(ret) {
    if (ret.msg != 'ok') {
        alertError(ret.msg);
    }
    return ret;
}