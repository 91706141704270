import './polyfills';
import './index.css';
import '@rainbow-me/rainbowkit/styles.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Button, ConfigProvider, theme } from 'antd';

import { darkTheme , connectorsForWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import {
  arbitrum,
  base,
  mainnet,
  optimism,
  polygon,
  sepolia,
  zora,
  goerli,
  arbitrumSepolia
} from 'wagmi/chains';
import { metaMaskWallet, okxWallet, tokenPocketWallet, imTokenWallet, coinbaseWallet, bitKeepWallet } from '@rainbow-me/rainbowkit/wallets';

import App from './App';


const projectId = '1f3ddfd28be419ce4cbd99c03397f94d';
const appName = 'test';

// const { chains, publicClient, webSocketPublicClient } = configureChains(
//   [
//     // goerli,
//     mainnet,
//     polygon,
//     optimism,
//     arbitrum,
//     base,
//     zora,
//     ...(process.env.REACT_APP_ENABLE_TESTNETS === 'true' ? [sepolia] : []),
//   ],
//   [publicProvider()]
// );
const { chains, publicClient, webSocketPublicClient } = configureChains(
  [arbitrum],
  [publicProvider()]
);



// const { connectors } = getDefaultWallets({
//   appName: 'test',
// 	projectId: '1f3ddfd28be419ce4cbd99c03397f94d',
//   chains,
// });

const connectors2 = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [
      metaMaskWallet({ projectId, chains }),
      coinbaseWallet({ appName, chains }),
      okxWallet({ projectId, chains }),
      imTokenWallet({ projectId, chains }),
      tokenPocketWallet({ projectId, chains }),
      bitKeepWallet({ projectId, chains })
    ],
  },
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: connectors2,
  publicClient,
  webSocketPublicClient,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <WagmiConfig config={wagmiConfig}>
    <RainbowKitProvider theme={darkTheme()} chains={chains} locale="en-US" modalSize="compact">
      <ConfigProvider
        theme={
          {
            "token": {
              "colorPrimary": "#22E531",
              "colorInfo": "#22E531",
              "borderRadius": 60,
              "colorBgElevated": "#12141a"
            },
            algorithm: theme.darkAlgorithm
          }
        }         
      >
        <App />
      </ConfigProvider>
    </RainbowKitProvider>
  </WagmiConfig>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
