import React, { useState, useEffect } from "react";
import { ConnectButton } from '@rainbow-me/rainbowkit';
import styled from "styled-components";


const Main = ({ className }) => {
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        resizeWindow();
        window.addEventListener('resize', resizeWindow);
    }, []);
    const resizeWindow = () => {
        console.log('resize window', window.innerWidth);
        setIsMobile(window.innerWidth < 800);
    }



    return (
        <div className={className}>
            <video className="for-mobile" controls autoPlay="autoplay" muted loop="loop">
                <source src={process.env.PUBLIC_URL + "/imgs/animation-m.mp4"} type="video/mp4" />
            </video>
            <video controls className="for-pc" autoPlay="autoplay" muted loop="loop">
                <source src={process.env.PUBLIC_URL + "/imgs/animation.mp4"} type="video/mp4" />
            </video>
            <div className="con">
                <header>
                    <img src={process.env.PUBLIC_URL + "/imgs/logo.png"} width="120px" />
                    <div className="right-link">
                        <a href="#" target="_blank">
                            <img width="24px" src={process.env.PUBLIC_URL + "/imgs/witter.png"} />
                        </a>
                        <a href="#" target="_blank">
                            <img width="24px" src={process.env.PUBLIC_URL + "/imgs/telegram.png"} />
                        </a>
                    </div>
                </header>
                <div className="content">
                    <div>DePIN Made Simple<br />For Everyone</div>
                    <span onClick={() => { window.nav("home") }}>Launch App</span>
                </div>
            </div>
        </div>
    );
};

export default styled(Main)`
    display: block;
    video{
        width: 100%;
        height:100vh;
        display: block;
        overflow: hidden;
        z-index: -1;
        background-size: cover;
    }
    .con{
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        display: block;
        z-index: 1;
        header{
            width: calc(100% - 60px);
            padding:18px 30px;
            height:22px;
            display: flex;
            justify-content: space-between;
            .right-link{
                display: flex;
                gap:16px;
            }
        }
        .content{
            margin-left:5%;
            margin-top:15%;
            display: flex;
            gap:80px;
            flex-direction: column;
            div{
                font-size: 60px;
                font-weight: 900;
                line-height: 74px;
            }
            span{
                width:115px;
                height:43px;
                border-radius: 8px;
                font-size: 16px;
                background: #22E531;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                color:#111316;
                cursor: pointer;
                &:hover{
                    background-color: #55fa63;
                }
            }
        }
    }
    @media (min-width: 800px) {
        .for-mobile{
            display: none;
        }
    }
    @media (max-width: 800px) {
        .for-pc{
            display: none;
        }
        .con{
            .content{
                margin-top:25%;
                gap:40px;
                text-align: center;
                align-items: center;
                justify-content: center;
                div{
                    font-size: 32px;
                    font-weight: 900;
                    line-height: 39px;
                }
            }
        }
    }
`
