import { notification } from "antd";
import copyH from "copy-to-clipboard";

let t1 = { ex: 0, title: "" };
let t2 = { ex: 0, title: "" };
let t3 = { ex: 0, title: "" };
export function alertInfo(title) {
  return new Promise((resolve, reject) => {
    let modal = window.modalApi.info({
      title,
      onOk: () => {
        // window.modalApi.destroy();
        resolve();
      },
    });
  });
}
export function alertOk(title) {
  window.modalApi.success({
    title,
  });
}
export function copy(txt) {
  msgOK('Copied');
  copyH(txt);
}
export function alertError(title) {
  let now = new Date().valueOf();
  if (t1.title == title && t1.ex > now) {
    return;
  }
  t1 = {
    ex: now + 3000,
    title
  };
  window.modalApi.error({
    title: formatMsg(title),
  });
}
export function shareFile(url) {
  return new Promise((resolve, reject) => {
    let modal = window.modalApi.info({
      title: null,
      footer: null,
      width: 800,
      icon: null,
      content: (
        <div className="mypopu">
          <div className="title">Copy the link to share</div>
          <div className="con">
            <div className="show-txt">{url}</div>
          </div>
          <div className="footer">
            <span
              className="btn btn-primary"
              onClick={() => {
                copyH(url);
                notiOK("Copy successful");
              }}
            >
              Copy
            </span>
            <span onClick={() => window.modalApi.destroy()} className="btn btn-default">
              Cancel
            </span>
          </div>
        </div>
      ),
      onOk: () => {
        // window.modalApi.destroy();
        resolve();
      },
    });
  });
}
window.loadingTipsIsShow = false;
export function loading(content) {
  if (window.loadingTipsIsShow || !content) {
    window.loadingTipsIsShow = false;
    window.messageApi.destroy();
    return;
  }
  window.loadingTipsIsShow = true;
  window.messageApi.loading({
    content,
    duration: 0,
  });
}
export function confirm(title, okText = "OK", cancelText = "Cancel") {
  return new Promise((resolve, reject) => {
    window.modalApi.confirm({
      title,
      cancelText,
      okText,
      onOk: () => {
        resolve(true);
      },
      onCancel: () => {
        resolve(false);
      },
    });
  });
}
notification.config({
  placement: "topRight",
  top: 70,
  duration: 5,
  rtl: true,
});
export function noti(message) {
  window.notificationApi.open({ message });
}
export function notiOK(message) {
  window.notificationApi.success({ message });
}
export function notiError(message) {
  let now = new Date().valueOf();
  if (t2.title == message && t2.ex > now) {
    return;
  }
  t2 = {
    ex: now + 3000,
    title: message
  };
  window.notificationApi.error({ message: formatMsg(message) });
}
export function msgOK(msg) {
  window.messageApi.success(msg);
}
export function msgError(msg) {
  let now = new Date().valueOf();
  if (t3.title == msg && t3.ex > now) {
    return;
  }
  t3 = {
    ex: now + 3000,
    title: msg
  };
  window.messageApi.error(formatMsg(msg));
}
function formatMsg(msg) {
  if (typeof msg != 'string') {
    return msg;
  }
  let a = msg.slice(0, 1);
  let b = msg.slice(1);
  let c = msg.slice(-1);
  let d = "";
  if (c != '.') {
    d = '.';
  }
  return a.toLocaleUpperCase() + b + d;
}