import React, { useState, useEffect } from "react";
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useSendTransaction, useAccount, useSignMessage, useBlockNumber } from 'wagmi'
import { parseEther } from 'viem';
import { Space, Spin, Button, Modal, message, notification } from "antd";
import { alertInfo, alertOk, alertError, notiError } from "./utils/antd-helper";

import api from './controls/api';
import store from './utils/store';
import * as formatter from './utils/formatter';
import webconfig from './webconfig';

import Dashborad from './components/Dashborad';
import Home from './components/Home';
import Start from './components/Start';
import Test from './components/Test';

let lastAddr = null;
let uinfo = null;

const App = () => {
  const account = useAccount();
  const [logining, setLogining] = useState(false);
  const [showPage, setShowPage] = useState('start');
  const [projects, setProjects] = useState([]);
  const [showLeftPannel, setShowLeftPannel] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showNavFixed, setShowNavFixed] = useState(false);
  const [currProjectId, setCurrProjectId] = useState(1);
  const [messageApi, contextHolder] = message.useMessage();
  const [notificationApi, noticontextHolder] = notification.useNotification();
  const [modalApi, modalcontextHolder] = Modal.useModal();
  const blockNumber = useBlockNumber({
    chainId: webconfig.chainId,
    watch: true
  });

  async function init() {
    let ret = await api.getProjects();
    if (ret.msg != 'ok' || !ret.data || ret.data.length == 0) {
      return;
    }
    setProjects(ret.data);
    setCurrProjectId(ret.data[0].id);
    // setProjects([
    //   { id: 1, label: "Project name1" },
    //   { id: 2, label: "Project name2" },
    //   { id: 3, label: "Project name3" }
    // ]);
  }
  useEffect(() => {
    // setInterval(() => console.log("blockNumber1", blockNumber.status, blockNumber.data), 1000);
  }, []);

  useEffect(() => {
    window.nav = setShowPage;
    window.messageApi = messageApi;
    window.notificationApi = notificationApi;
    window.modalApi = modalApi;
    init();
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
  }, []);

  const resizeWindow = () => {
    let w = window.innerWidth > 800;
    setShowLeftPannel(w);
    setIsMobile(!w);
  }

  useEffect(() => {
    if (account?.address) {
      store.set('addr', account.address);
      if (account.address == lastAddr) {
        // console.log('account not change');
      } else {
        lastAddr = account.address;
        console.log('account login');
      }
    } else {
      store.remove('addr');
      if (lastAddr) {
        console.log('account logout');
      } else {
        // console.log('account not change');
      }
    }
  }, [account]);
  const navClick = (id) => {
    if (isMobile) {
      if (showNavFixed) {
        setCurrProjectId(id);
        setShowNavFixed(false);
      } else {
        setShowNavFixed(true);
      }
    } else {
      setCurrProjectId(id);
    }
    setShowPage('home');
  }
  if (window.location.href.includes('test')) return <Test />;
  if (showPage == 'start') return <Start />;

  

  return (
    <div>
      {/* <div className="header"><a href="/" title="Go back to cess home"></a>{userinfo&&<i title="Logout" onClick={()=>forLogin.logout(checkLogin)}></i>}</div> */}
      {contextHolder}{noticontextHolder}{modalcontextHolder}
      <div className="header">
        <div className="nav">
          {
            projects.map((t, i) => {
              return (<div key={i} className={showPage == 'home' && t.id == currProjectId ? "curr" : ""} onClick={() => navClick(t.id)}>{t.label}</div>)
            })
          }
          {isMobile && account?.address && <div onClick={() => { setShowNavFixed(true); }} className={showPage == 'dashborad' ? "for-mobile curr" : "for-mobile"}>Dashborad</div>}
          <div className="disabled">More</div>
        </div>
        <div className={showNavFixed ? "nav-mobile for-mobile" : "none"}>
          {
            projects.map((t, i) => {
              return (<div key={"a" + i} className={showPage == 'home' && t.id == currProjectId ? "curr" : ""} onClick={() => navClick(t.id)}>{t.label}</div>)
            })
          }
          <div onClick={() => { setShowPage('dashborad'); setShowNavFixed(false); }} className={showPage == 'dashborad' ? "curr" : ""}>Dashborad</div>
        </div>
        <div className="login">
          {account?.address && <div onClick={() => setShowPage('dashborad')} className={showPage == 'dashborad' ? "btn-dashborad curr" : "btn-dashborad"}>
            <img src={showPage == 'dashborad' ? process.env.PUBLIC_URL + "/imgs/dashborard-curr.png" : process.env.PUBLIC_URL + "/imgs/dashborard.png"} width="24px" />
            Dashborad
          </div>}
          <div><ConnectButton showBalance={false} accountStatus='avatar' chainStatus="none" /></div>
        </div>
      </div>
      <div className="left-pannel" style={{ left: showLeftPannel ? "0px" : "-300px" }}>
        <div className="logo"><img src={process.env.PUBLIC_URL + "/imgs/logo.png"} width="120px" /></div>
        <div className="menu">
          {
            showPage == 'home' ? (<div className="item curr"><img src={process.env.PUBLIC_URL + "/imgs/m1-curr.png"} />Decentralized Computility</div>)
              : <div className="item"><img src={process.env.PUBLIC_URL + "/imgs/m1.png"} />Decentralized Computility</div>
          }
          <div className="item disabled"><img src={process.env.PUBLIC_URL + "/imgs/m2.png"} />XESS.DATA</div>
          <div className="item disabled"><img src={process.env.PUBLIC_URL + "/imgs/m2.png"} />DePIN Aggregator</div>
          <div className="item disabled"><img src={process.env.PUBLIC_URL + "/imgs/m2.png"} />Data Market</div>
          <div className="item disabled"><img src={process.env.PUBLIC_URL + "/imgs/m3.png"} style={{ opacity: 0.4 }} />RWA Market</div>
          <div className="item disabled"><img src={process.env.PUBLIC_URL + "/imgs/m4.png"} style={{ opacity: 0.4 }} />DEPIN Scan</div>
        </div>
        <div className="bottom-link">
          <a href="#" target="_blank">
            <img width="24px" src={process.env.PUBLIC_URL + "/imgs/witter.png"} />
          </a>
          <a href="#" target="_blank">
            <img width="24px" src={process.env.PUBLIC_URL + "/imgs/telegram.png"} />
          </a>
        </div>
      </div>
      {(showLeftPannel || showNavFixed) && <div className="left-pannel-bg" onClick={() => { setShowLeftPannel(false); setShowNavFixed(false); }}></div>}
      {!showLeftPannel && <div className="fixed-btn only-mobile" onClick={() => setShowLeftPannel(true)}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="icon/menu">
            <path id="Union" fill-rule="evenodd" clipRule="evenodd" d="M20 5.5H4V4.5H20V5.5ZM20 12.5H4V11.5H20V12.5ZM4 19.5H20V18.5H4V19.5Z" fill="black" />
          </g>
        </svg>
      </div>}
      <div className="containner">
        {
          showPage == 'home' ? <Home projectid={currProjectId} accountAddr={account?.address} /> :
            showPage == 'dashborad' ? <Dashborad accountAddr={account?.address} /> :
              <div className="loading-box"><Spin tip="Loading" size="large" /></div>
        }
      </div>
      {
        account && account.address ? <div id="myaddr" data-addr={account.address}></div> : ""
      }
    </div>
  );
};

export default App;
