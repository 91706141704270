import webconfig from '../webconfig';
import api from "./api";
import store from '../utils/store';
import broadcast from "../utils/broadcast";
import * as formatter from '../utils/formatter';
import common from '../utils/common';
import got from '../utils/request';
import valitor from '../utils/valitor';
import { noti, alertInfo, alertOk, alertError, msgOK, msgError } from "../utils/antd-helper";

let cache = {};
export default {
    getData
}
async function getData(projectid, setLoading, setData = () => { }) {
    let id = new Date().valueOf();
    cache.lastId = id;
    let key = "p" + projectid;
    if (!setLoading) {
        setLoading = () => { };
    }
    if (cache[key]) {
        setData(cache[key]);
    } else {
        setData([]);
        setLoading(true);
    }
    let ret = await api.getProducts(projectid);
    if (ret.msg != 'ok') {
        return ret;
    }
    let data = [];
    let nfts = await api.getNFTs();
    if (nfts.msg != "ok" || !nfts.data || nfts.data.length == 0) {
        return nfts;
    }
    nfts = nfts.data;
    for (let t of ret.data) {
        if (id != cache.lastId) {
            return;
        }
        let o = {
            id: t.id,
            projectId: t.projectId,
            productName: t.productName,
            title2: t.subtitle,
            totalMinted: 0,
            totalReward: t.totalRewards,
            reward24H: t.dailyRewards,
            totalNfts: t.totalNfts
        }
        // let tmp = await api.getNFTs(t.id);
        let nft = nfts.filter(n => n.productId == t.id);
        if (nft.length == 0) continue;
        let packages = [];
        nft.forEach(p => {
            let obj = {
                id: p.id,
                address: p.Address || p.address,
                pic: formatter.formatNFTImageUrl(p.img),
                packageName: p.name,
                price: parseInt(p.price),
                multiplier: p.multiplier,
                theTerm: p.term.replace("days", ""),
                total: p.totalAmount,
                minted: p.mintedAmount,
                mintedPer: "0%",
                isLive: p.list,
                description: p.description,
                blockchain: p.blockchain,
                metadata: p.metadata,
                ipfsLink: p.ipfsLink
            };
            o.totalMinted += obj.minted;
            if (obj.total > 0) {
                obj.mintedPer = ((obj.minted * 100) / obj.total).toFixed(2) + "%";
            }
            packages.push(obj);
        });
        if (packages.length) {
            o.packages = packages;
            data.push(o);
        }
    }
    cache[key] = data;
    if (id == cache.lastId) {
        setData(data);
    }
    setLoading(false);
    return ret;
}