import _ from "lodash";
import webconfig from "../webconfig";

export function formatterSizeStr(size) {
  let obj = formatterSize(size);
  return obj.size + " " + obj.ext;
}
export function toLocaleString(coin) {
  if (!coin) return coin;
  if (_.isString(coin)) {
    coin = _.toNumber(coin);
  }
  if (coin == 0) return 0;
  coin = Math.round(coin * 100) / 100;
  coin = coin.toLocaleString("zh", { style: "decimal" });
  return coin;
}
export function formatterSize(count) {
  if (!count) {
    console.log("!count", count);
    return {
      size: 0,
      ext: "KB",
    };
  }
  if (_.isString(count)) {
    count = _.toNumber(count);
  }
  if (count === 0) return "0 KB";
  let k = 1024;
  let currencyStr = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let i = 0;
  for (let l = 0; l < 8; l++) {
    if (count / Math.pow(k, l) < 1) {
      break;
    }
    i = l;
  }
  return {
    size: (count / _.round(Math.pow(k, i))).toFixed(2),
    ext: currencyStr[i],
  };
}
export function formatBalance(balance) {
  if (!balance) {
    return "";
  }
  if (typeof balance == "string") {
    balance = parseInt(balance.split(",").join(""));
  }
  if (typeof balance == "object" && balance.free) {
    balance = parseInt(balance.free.toString());
  }
  if (isNaN(balance)) {
    return balance;
  }
  return balance / 1000000000000000000;
}
export function formatAddress(addr) {
  if (!addr) return "";
  if (addr.length < 10) return addr;
  return addr.slice(0, 5) + "..." + addr.slice(-5);
}
export function formatAddressLong(addr) {
  if (!addr) return "";
  if (addr.length < 26) return addr;
  return addr.slice(0, 13) + "..." + addr.slice(-13);
}
export function fixed(n) {
  return Math.floor(n * 100) / 100;
}
export function formatTime(time) {
  let h = parseInt((time / 60 / 60) % 24);
  h = h < 10 ? "0" + h : h;
  let m = parseInt((time / 60) % 60);
  m = m < 10 ? "0" + m : m;
  let s = parseInt(time % 60);
  s = s < 10 ? "0" + s : s;
  // return [h, m, s]
  if (h > 0) {
    return h + ":" + m + ":" + s;
  } else {
    return m + ":" + s;
  }
}
export function timestr(fmt, time) {
  var now = time ? new Date(time) : new Date();
  var o = {
    "M+": now.getMonth() + 1, //月份
    "d+": now.getDate(), //日
    "h+": now.getHours(), //小时
    "m+": now.getMinutes(), //分
    "s+": now.getSeconds(), //秒
    "q+": Math.floor((now.getMonth() + 3) / 3), //季度
    S: now.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (now.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
}
export function formatNFTImageUrl(name) {
  let baseUrl = window.apiURL || "https://uat-xess.cess.network/api";
  return baseUrl + "/xess/customer/nft-img/" + name;
}